<template>
  <div class="block-tabs" v-if="tabsView.length">
    <span v-for="(el, ind) in tabsView" :key="ind"
      class="btn-view"
      :class="{active: modeView == el.val}"
      @click="chgModeView(el.val)"
      @keydown.f7="chgModeView(el.val)">
      <i class="fa" :class="el.icon" aria-hidden="true"></i>
    </span>
  </div>
  <h1>
    <span>Товары</span>
  </h1>
  <transition name="fade">
    <LoaderTreeSpin v-if="loader" />
    <div v-else-if="tabsView.length" class="wrp-list">
      <div class="column-left" :class="{open: showCategory}">
        <div class="btn-toogle-left"
          @click="showLeft" :class="{open: showCategory}" @keydown.f7="showLeft">
          <span class="size-birka" v-if="catsIds.length > 0">{{catsIds.length}} </span>
          <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </div>
        <IerarhDef
          :class="{h93: 1}"
          :stock="stock"
          :setCats="false"
          :onlyOne="modeView"
          :key="keycat"
          action="/v2/category/list-by-stock-prod/"
          @transferCats="selCatIds"
          @transferProds="selProdIds"
          @chgList="changeShow"
        />
      </div>
      <div class="column-central" :class="{'with-left': showCategory}">
        <component v-if="viewComponent.length" :is="viewComponent" :key="key"
          :action="actionInit" :outerFilter="actionFilter"></component>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

import ListManage from '@/components/v2/cross/list/ListManage.vue';
import ListSetka from '@/components/v2/cross/list/ListSetka.vue';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';
import IerarhDef from '@/components/v2/cross/ierarh/IerarhDef.vue';

export default {
  name: 'IfaceProducts',
  components: {
    ListManage, ListSetka, LoaderTreeSpin, IerarhDef,
  },
  data() {
    return {
      l_curr_stock: localStorage.getItem('stock'),
      showCategory: false,

      filter: {},
      list: [],
      head: [],
      tablefilter: [],
      instrumental: [],
      key: 0,
      keycat: 0,
      viewComponent: '',

      stock: 0,
      catsIds: [],
      prodsIds: [],
      actionConfig: '/v2/product/iface-config',
      actionInit: '/v2/product/list',
      actionOrder: '/scm/product/form-order',

      showFormOrder: false,
      modalProducts: [],
      modalGenProduct: {},
      modalBuffer: {},
      modalStock: '',

      showFilter: false,

      currParam: {},
      currProduct: {},
      currIndex: [],

      modeView: 0,
      tabsView: [],

      answerText: '',
      errText: '',
      errors: [],

      loader: false,
      loaderBody: false,
    };
  },
  created() {
    this.stock = this.gettersAuthData.stocks.value.val;
    this.keycat += 1;
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters('auth', {
      gettersAuthData: 'getAuthData',
    }),
    actionFilter() {
      const data = { stock: this.stock };
      if (Array.isArray(this.catsIds) && this.catsIds.length > 0) {
        data.c_category = this.catsIds;
      }
      if (Array.isArray(this.prodsIds) && this.prodsIds.length > 0) {
        data.c_product = this.prodsIds;
      }
      data.view = this.modeView;

      return data;
    },
    currSuppliers() {
      let suppliers = [];
      if (this.currProduct.suppliers) {
        suppliers = this.currProduct.suppliers;
      }
      return suppliers;
    },
  },
  methods: {
    init() {
      axios.get(`${this.actionConfig}?stock=${this.stock}`)
        .then((res) => {
          if (res.data.success === 1) {
            this.tabsView = res.data.tabs_view;
            this.modeView = res.data.tabs_active;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showLeft() {
      this.showCategory = !this.showCategory;
    },
    selCatIds(ids) {
      this.prodsIds = [];
      this.catsIds = ids;
      if (this.modeView === 0) {
        this.viewComponent = 'ListManage';
      } else {
        this.viewComponent = 'ListSetka';
      }
      this.key += 1;
    },
    selProdIds(ids) {
      this.catsIds = [];
      this.prodsIds = ids;
      this.key += 1;
    },
    chgModeView(val) {
      if (this.modeView === val) return;
      this.modeView = val;
      this.keycat += 1;
    },
  },
};
</script>

<style scoped>
.size-birka{
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  margin-right: 2px;
}
.btn-toogle-left{
  height: 20px;
  width: 40px;
  position: absolute;
  top: -1px;
  right: -45px;
  background-color: #ffffff;
  color: #1c2428;
  padding: 2px 2px;
  border: 1px solid #dadee0;
  border-left: none;
  line-height: 21px;
  cursor: pointer;
  text-align: center;
  transition: width 0.1s, right 0.1s, background-color 0.1s;
}
.btn-toogle-left.open{
  right: -53px;
  width: 48px;
}
.btn-toogle-left:hover{
  background-color: #efefef;
}
.btn-toogle-left .fa{
  transition: transform 0.5s;
}
.btn-toogle-left.open .fa {
  transform: rotate(180deg);
}
.column-left{
  height: calc(100vh - 93px);
  float: left;
  width: 250px;
  margin-left: -250px;
  position: sticky;
  background-color: white;
  border: 1px solid #dadee0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: margin-left 0.1s;
  position: relative;
  top: 0px;
  z-index: 1;
}
.column-left.open{
  margin-left: 0px;
}
.column-left:hover{
  /*overflow-y: auto; */
}
.column-central{
  height: calc(100vh - 93px);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  margin-left: 0px;
  /* background: #F4ECCE; */
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: margin-left 0.1s;
}
.column-central.with-left{
  margin-left: 260px;
}

.gen-header{
  border-bottom: 1px solid #000;
  margin-bottom: 15px;
}
h1{
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  margin-top: 5px;
  margin-bottom: 10px;
}
h1 span{
  background: transparent;
  padding: 0px 12px;
}

.wrp-list{
  /* height: calc(100vh - 91px); */
  overflow: hidden;
  overflow-y: auto;
}

.wrp-info-line {
  text-align: left;
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  background: #ebf6ff;
}
.wrp-info-line .title {
  font-weight: bold;
  font-size: 18px;
  padding-left: 8px;
  padding-top: 8px;
  margin-bottom: 8px;
}

.wrp-info-line table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-info-line table td, .wrp-info-line table th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.wrp-info-line table th{
  background: transparent;
}

.wrp-info-line .table-title th {border-top: 0px;}
.wrp-info-line table tr:hover td {
  background: #eee;
}

.wrp-text-warn-1{
  padding-top: 34px;
  background: #fff;
  padding-bottom: 56px;
}

.right-top-icons{
  margin-top: -20px;
  z-index: 1;
  position: relative;
  background: #bbb;
  float: right;
}
.flag-line{
  display: inline-block;
  vertical-align: top;
}
.right-top-icons .flag-line{
  margin: 0px 0px 0px 2px;
  background: #efefef;
  padding: 4px 8px 2px;
  text-align: center;
  min-width: 30px;
  cursor: default;
}
.right-top-icons .flag-line > div {
  display: inline-block;
}
.icon-head{margin-right: 12px;}

.flag-line.blue,
.flag-line.green,
.flag-line.yellow,
.flag-line.red,
.flag-line.black,
.flag-line.gray{
  background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,
                    transparent 0,
                    transparent 50%,
                    hsla(0,0%,100%,.15) 0,
                    hsla(0,0%,100%,.15) 75%,
                    transparent 0,
                    transparent);
  color: #fff;
}
.flag-line.blue{ background-color: #5baade;}
.flag-line.green { background-color: #5cb85c;}
.flag-line.yellow { background-color: #f0ad4e; color: #000;}
.flag-line.red { background-color: #d9534f;}
.flag-line.black { background-color: #757575;}

.flag-line.new{ background: #e7e992; color: #136e23; }

.block-head{
  background: #e6e6e6;
  padding: 6px 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}
.block-head-line{
  font-size: 14px;
  font-weight: bold;
  padding-left: 6px;
  padding-right: 6px;
  padding-top:4px;
  line-height: 18px;
  position: relative;
}
.block-head-line.new{
  background-color: #e7e992;
}
.name-stick{
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 8px;
  line-height: 10px;
  font-weight: bold;
  color: #136e23;
}

.block-tabs{
  margin-top: 5px;
  margin-right: 12px;
  float: right;
}
.btn-view{
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 28px;
  height: 28px;
  line-height: 28px;
  transition: all 0.5s;
  font-weight: bold;
  color: #2c3e50;
  background: #f6f8fa;
  border: 1px solid #dadee0;
  padding: 0px;
  text-align: center;
  font-size: 13px;
}
.btn-view.active{
  background: #bcede8;
  border-color: #84afaa;
}
.wrp-graph{
  padding-left: 10px; padding-right: 10px;
  background: white;
}
.head-graph{
  padding-bottom: 20px;
  background: white;
  position: relative;
}
.line-1{
  background: #d2d2d2;
  padding: 3px 10px;
}
.line-1:first-child{
  padding-top: 10px;
}
.line-1:last-child{
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 24px;
  color: #cb2222;
}
.inline-block{
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
}
.inline-block .title{
  font-weight: bold;
  margin-bottom: 10px;
}
.inline-block table{
  border-collapse: collapse;
}
.inline-block table th{
  background: transparent;
  padding: 4px;
}
.inline-block tr td{
  padding: 4px;
  border-bottom: 1px solid #aaa;
}
.inline-block tr:empty{
  display: none;
}
.btn-m{
  font-size: 22px; line-height: 22px;
  display:inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #556b7d;
}

.big-icon{
  line-height: 32px;
  font-size: 18px;
}
.wrp-icon-filter{
  width: 100%;
  text-align: center;
  background: #e6e6e6;
}

.table-filter .short-input-filter{
  max-width: 100%
}
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

table th {
  position: sticky;
  top: 50px;
  background: white;
  z-index: 10;
  transition: width 1s;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.btn-filter:hover{
  cursor: pointer;
}
.btn-active{
  color: #5981ce;
}
.tr-head span{
  margin-right: 15px;
}
.right{
  float: right;
}
.modal-form{
  text-align: center;
}
.wrp-table{
  margin-bottom: 40px;
}
.short-input{
  width:100px;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.icon-last-status{
  font-size: 22px;
  font-weight: bold;
  padding-right: 6px;
}

.btn-close{
  float: right;
  font-size: 20px;
  color: #780c0c;
  cursor: pointer;
}
.btn-close:hover {
  color: #bf1111;
}

.btn-close-big{
  position: absolute;
  right: 20px;
  top: 4px;
  font-size: 30px;
  line-height: 30px;
  color: #780c0c;
  cursor: pointer;
  transition: color 0.7s;
}
.btn-close-big:hover {
  color: #bf1111;
}

.err-text{
  margin-top: -18px;
  margin-bottom: 10px;
  color: #b34040;
  background: #ffdfdf;
  padding: 4px;
}

.block .column{
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  margin-right: 12px;
  margin-left: 12px;
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: botton;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}
.table-iface th.hover:hover{
  background: #dfdddd;
}
.table-iface th.active{
  background: #cbcaca;
}
.table-iface th.active:hover{
  background: #bfbbbb;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.instrumental-item{margin: 0px 1px;}

.btn-field{color: #556b7d; font-size:22px; line-height:22px; margin-right:4px;}
.btn-field:hover{color: #2f4a60;}

.btn-a{color: #556b7d}
.btn-a:hover {color: #2f4a60; text-decoration: underline;}

.btn-cart{
  display: block;
  width: 100%;
  background: #44b752;
  border: 1px solid #2c9539;

  cursor:pointer;
  color:#fff;
  text-align: center
}
.btn-cart:hover{background: #2c9539}
.btn-cart.disabled{
  background: #a4e5ac;
  border-color: #ddd;
  position: relative;
}

.btn-cart.disabled:after{
  content: '';
  position: absolute;
  top: 5px;
  left: 40%;
  margin-left: -15px;
  width: 60px;
  height: 30px;
  border-top: 2px solid red;
  transform: rotate(-40deg);
}

tr.disabled {
  opacity: 0.5;
}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #d2d2d2;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.context-menu--item{display:block;
line-height: 20px;
cursor:pointer;
padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
  text-align: center;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
    background-color: #5baade;
}

.progress-bar-black {
    background-color: #757575;
}
.progress-bar-warning {
    background-color: #f0ad4e;
}

.progress-bar-danger {
    background-color: #d9534f;
}

.progress-bar-success {
    background-color: #5cb85c;
}

.white-text {
    color: #fff;
}
.th-icon{font-size: 21px;}

.buffer-lbl{
  position: absolute;
  width: 100%;
}

.btn-filter{
  text-align: center;
}
.btn-group{
  cursor: pointer;
  border: none;
  padding: 0px;
  font-size: 24px;
  color: #4a975b;
}

</style>
