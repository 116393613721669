<template>
  <div class="ext-settings">
    <div class="one-line plate-white" v-if="pagination.cnt">
      <span>{{(pagination.page - 1) * pagination.limit}}</span>
      <span> - </span>
      <span v-if="(pagination.cnt - (pagination.page - 1) * pagination.limit) > pagination.limit">
        {{(pagination.page - 1) * pagination.limit + pagination.limit}}
      </span>
      <span v-else>{{pagination.cnt}}</span>
      <span> из </span>
      <span>{{pagination.cnt}}</span>
    </div>
  </div>
  <transition name="fade">
    <LoaderTreeSpin v-if="loaderBody" />
    <table v-else class="table">
      <thead>
        <tr class="tr-head">
          <th v-for="(el, ind) in head" :key="ind"
          :title="el.lbltitle"
          :class="{hover: el.sort.show && ind > 0, active: el.sort.active > 0,
            'btn-filter': ind === 0, 'btn-active': showFilter && ind === 0}"
          @click="sort(ind)">
            <div class="th-inner one-line">
              <MenuCollapse v-if="ind === 0 && tableMenu.hasOwnProperty('params')"
                class="small"
                :ind="5000"
                :params="tableMenu.params"
                @showComponent="showForm"
                @chgList="chgList"
              />
              <i v-if="ind > 0 && el.sort.show"
                class="fa"
                :class="{
                  'i-color': el.sort.active == 0,
                  'fa-sort-amount-asc': el.sort.val == 0,
                  'fa-sort-amount-desc': el.sort.val == 1
                }"
                aria-hidden="true"></i>

              <span v-if="el.type === 0">{{el.lbl}}</span>
              <span v-if="el.type === 1" v-html="el.lbl" class="th-icon"
                :style="{'color': el.color}" ></span>
            </div>
          </th>
        </tr>
        <tr v-if="tablefilter.length">
          <th :title="el.title" v-for="(el, ind) in tablefilter" :key="ind">
            <div class="th-inner two-line">
              <template v-if="ind === 0">
                <i class="fa fa-filter" aria-hidden="true"></i>
              </template>
              <InputTextShort v-if="el.type == 'I'"
                v-model="el.value"
                @change="chgSubFilter"
                />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(fields, index) in list" :key="index">
          <td v-for="(field, index2) in fields" :key="index2">
            <template v-if="field.type === 'M'">
              <MenuCollapse
               :ind="index"
               :params="field.params"
                @showComponent="showForm"
                @chgList="chgList"
              />
              <div class="classs" aria-hidden="true"></div>
            </template>
            <template  v-if="field.type === 'SL'">
              <div aria-hidden="true">
                <span v-if="el.level === 2"><i class="fa fa-share"></i></span>
                <span v-if="el.level > 2">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-share"></i></span>
                {{field.value}}
              </div>
            </template>
            <template  v-if="field.type === 'C'">
              <div aria-hidden="true">
                <Checkbox v-model="field.checked"
                  @change="changeIdsOperations(field.value, field.checked)" />
              </div>
            </template>
            <template v-if="field.type === 'N'">
              <div aria-hidden="true" :title="field.value" class="borderAfter">
                {{field.value}}
              </div>
            </template>
            <template v-if="field.type === 'S'">
              <div aria-hidden="true" :title="field.value">
                {{field.value}}
              </div>
            </template>
            <template v-if="field.type === 'BO'">
              <div class="field-icon">
                <i v-if="field.value > 0" class="fa fa-check" aria-hidden="true"></i>
                <i v-else class="fa fa-times" aria-hidden="true"></i>
              </div>
            </template>
            <template v-if="field.type === 'B'">
              <div class='progress progress-striped'>
                <div class='progress-bar' :class="field.opt.color"
                  role='progressbar' :aria-valuenow='field.lbl'
                  aria-valuemin='0' aria-valuemax='100'
                  :style='{width: field.opt.w_block}'>
                  <span class='sr-only'>{{field.lbl}}</span>
                </div>
                <div v-if="field.opt.color_text"
                  class="buffer-lbl"
                  :class="field.opt.color_text" >{{field.value}} %</div>
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </transition>
  <transition name="fade">
    <ModalRight v-show="flagModalRight"
      :title="modalTitle"
      @close="flagModalRight = false;" :class="modalClass">
      <component :is="modalComponent" v-bind="modalConf"
        v-if="flagModalRight" @answer="chgList"></component>
    </ModalRight>
  </transition>
  <div v-if="pagination.cnt && pagination.pages.length > 1"
    class="wrapper-pagination">
    <div class="pagination-new">
      <div v-for="(el, ind) in pagination.pages" :key="ind"
        class="pagination-item" :class="{active: pagination.page == el.lbl}"
        @click="pagLoad(el)"
        @keydown.f7="pagLoad(el)">{{el.lbl}}
      </div>
    </div>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';
import axios from 'axios';
import InputTextShort from '@/components/v2/serj/InputTextShort.vue';
// import MiniBurgerMenu from '@/components/v2/serj/MiniBurgerMenu.vue';
import MenuCollapse from '@/components/v2/cross/menu/MenuCollapse.vue';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import Form from '@/components/v2/cross/form/Form.vue';
import ChartLiner from '@/components/v2/cross/chart/ChartLiner.vue';
import HorizontalMenuTab from '@/components/v2/cross/group/HorizontalMenuTab.vue';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';

export default {
  name: 'ListManageChanged',
  components: {
    InputTextShort,
    MenuCollapse,
    ModalRight,
    Form,
    ChartLiner,
    HorizontalMenuTab,
    LoaderTreeSpin,
    Checkbox,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    outerFilter: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['gotidsProdsOperations'],
  data() {
    return {
      idsOperations: [],
      l_curr_stock: localStorage.getItem('stock'),
      flagModalRight: false,
      modalComponent: '',
      modalConf: '',
      modalClass: 'w50',
      currIndex: -1,

      filter: {},
      list: [],
      head: [],
      tablefilter: [],
      tableMenu: {},
      modalTitle: '',

      instrumental: [],
      /*
      instrumentalNew:
      {
        title: 'Нажми на меня',
        button: 'button-th',
        icon: 'fa-superpowers',
        list:
        [
          {
            title: 'Настройка товаров',
            action: '/scm/product/set',
            params: {
              id: 2,
              url: '',
            },
            type: 'settings',
            icon: 'fa-sliders',
          },
        ],
      },
      */

      showFilter: false,

      currParam: {},
      currProduct: {},
      // currIndex: [],

      pagination: {},

      modeView: 0,
      headSetkaProducts: [],
      listSetkaProducts: [],
      tabsView: [],

      answerText: '',
      errText: '',
      errors: [],

      loader: true,
      loaderFilter: true,
      loaderBody: true,

      cntAll: 0,

      viewGroup: {},
      loadModal: false,
      showModalGroup: false,
      // End iFaceProduct
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loaderBody = true;

      axios.post(this.action, { filter: this.outerFilter })
        .then((res) => {
          if (res.data.success === 1) {
            // this.filter = res.data.filter;
            this.head = res.data.list.head;
            this.list = res.data.list.items;
            this.tablefilter = res.data.list.filter;
            if (res.data.list.menu) {
              this.tableMenu = res.data.list.menu;
            }

            // this.headSetkaProducts = res.data.headSetkaProducts;
            // this.listSetkaProducts = res.data.listSetkaProducts;
            // this.tabsView = res.data.tabsView;
            // this.modeView = res.data.tabsViewActive;

            this.pagination = res.data.list.pagination;

            // this.instrumental = res.data.instrumental;
          }
          this.loaderBody = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    changeIdsOperations(value, chk) {
      if (chk === 1) {
        this.idsOperations.push(value);
      } else {
        // Удаляет элемент из массива, если попадется одинаковый
        this.idsOperations = this.idsOperations.filter((valueChk) => valueChk !== value);
      }

      this.$emit('gotidsProdsOperations', this.idsOperations);
    },
    sort(ind) {
      if (!this.head[ind].sort.show || ind < 1) {
        return;
      }
      if (this.head[ind].sort.active) {
        if (this.head[ind].sort.val === 0) {
          this.head[ind].sort.val = 1;
        } else {
          this.head[ind].sort.val = 0;
        }
        this.loadList();
      } else {
        let i = this.head.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.head[ind].sort.active = 1;
          } else {
            this.head[i].sort.active = 0;
          }
        }
        this.loadList();
      }
    },
    chgSubFilter() {
      this.loadList(0);
    },
    loadList() {
      axios.post(this.action, {
        filter: this.outerFilter,
        extfilter: this.tablefilter,
        sort: this.head,
        page: this.pagination.page,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list.items;
          }
          this.loaderBody = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    pagLoad(el) {
      this.loaderBody = true;
      if (this.pagination.page === el.lbl) { return; }
      this.pagination.page = el.lbl;

      this.loadList();
      /*
      setTimeout(() => {
        this.loadList();
      }, 100);
      */
    },
    act(elem) {
      if (elem.type === 0) {
        this.$router.push(elem.act);
      }
    },
    test(data) {
      this.loader = data;
    },
    removeTr(index) {
      this.tbody.splice(index, 1);
    },
    showForm(options) {
      this.modalClass = options.w;
      this.modalComponent = options.component;
      this.modalConf = options.conf;
      this.currIndex = options.ind;
      this.flagModalRight = true;
    },
    chgList(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'ind')) {
        this.currIndex = data.ind;
      }
      if (data.act === 'chg') {
        this.list.splice(this.currIndex, 1, data.elem);
      } else if (data.act === 'add') {
        this.list.unshift(data.elem);
      } else if (data.act === 'del') {
        this.list.splice(this.currIndex, 1);
      }
    },
  },
  /*
  computed: {
    ...mapGetters('auth', {
      getterStock: 'getStock',
    }),
  },
  updated() {
    if (this.getterStock === '') {
      const stock = localStorage.getItem('stock');
      if (this.l_curr_stock !== stock) {
        this.l_curr_stock = stock;
        this.init();
      }
    } else {
      const stock = this.getterStock;
      if (this.l_curr_stock !== stock) {
        this.l_curr_stock = stock;
        this.init();
      }
    }
  },
  */
};
</script>

<style scoped>
table.table {
  border-spacing: 0px;
  border-collapse: collapse;
}
.table{
   background: #fff;
}
.container-up-table {
  display: flex;
  position: sticky;
  align-items: flex-end;
  flex-direction: column;
}

/*
table.table {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s;
}
table.table:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
*/

.links {
}
.links_el {
}
.select-checkbox {
}
.hover {
  cursor: pointer;
}
.i-color {
  color: #b4b8bb;
}
.fa-share {
  transform: rotate(180deg);
  transform:scale(1, -1);
/*  transition: width 0.1s ease-out;*/
}

.slide-form-leave-active {
  transition: width 0.1s ease-in;
}
.slide-form-enter-from, .slide-form-leave-to{
  width: 200%;
}
.slide-form-enter-to, .slide-form-leave-from{
  width: 100%;
}
.instrumental-new {
  display: flex;
  position: sticky;
  top: 50px;
  width: 100%;
  justify-content: flex-end;
}
.instrumental-new .btn-add-new {
    background: #f6f8fa;
    font-size: 13px;
    padding: 6px;
    display: inline-block;
    cursor: pointer;
    top: 1px;
    border: 1px solid #dadee0;
    position: relative;
    transition: all 0.3s;
}
.instrumental-new .btn-add-new:hover {
    border: 1px solid #6dc2ed;
}
.wrapper-pagination {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  left: 0px;
  bottom: -2px;
  width: 100%;
}
.pagination-new {
  display: flex;
/*  background: #f6f8fa;*/
/*  border-radius: 7px;*/
/*  padding: 5px 5px;*/
/*  border: 1px solid #dadee0;*/
}
/* Begin */
.pagination-item {
  list-style-type: none;
  display: inline-block;
  border-top: 1px solid #d7dadb;
  border-right: 1px solid #d7dadb;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 14px;
  color: #8b969c;
  background-color: white;
  cursor: pointer;
}
.pagination-item:first-child {
  border-left: 1px solid #d7dadb;
}

.pagination-item:hover,
.pagination-item.active {
  background-color: #42cffa;
  border: 1px solid #42cffa;
  color: #fff;
}

.pagination-item.first-number {
  border-left: 1px solid #d7dadb;
}

.pagination-link {
  padding: 1.1em 1.6em;
  display: inline-block;
  text-decoration: none;
  color: #8b969c;

  transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(20deg) skewY(0deg);
}
/* End */
.pagination-new .pag-el-new.active {
/*    background-color: #44b752;*/
/*    border: 1px solid #2c9539;*/
  border: 1px solid #6dc2ed;
  background-color: #f6f4f4;
/*  background-color: #0000003d;*/
}
.pagination-new .pag-el-new {
/*    vertical-align: top;*/
    /* background-color: #749be5; */
    /* border: 1px solid #4c7fe1; */
    /* color: #fff; */
    cursor: pointer;
    padding: 12px 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 100px;
    transition: all 0.3s;
    border: 1px solid #f6f8fa;
}
.pagination-new .pag-el-new:hover {
  border: 1px solid #6dc2ed;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.table-filter .short-input-filter{
  max-width: 100%
}
.table {
/*  width: 100%;*/
/*  border-left: 1px solid #dadee0;*/
  border-collapse: collapse;
/*  border-spacing: 0px;*/
}
.table thead {
  position: sticky;
  top: 0px;
  z-index: 1;
}
.table thead th {
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;

  padding: 0;
  margin: 0;
  vertical-align: top;
}
.table thead tr:last-child th{
  /* border-bottom: 1px solid #6dc2ed; */
}
.table thead tr:nth-child(n+2) th:first-child{
/*  border-right: 1px solid #dadee0;*/
  text-align: center;
}
.table thead tr th:first-child {
  border-top: none;
  border-left: none;
  width: 12px;
/*  border-radius: 8px 0 0 8px;*/

}
.table thead tr th:last-child {
/*  border-radius: 0 8px 8px 0;*/
  width: 100%;
}
.th-inner{
  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;

}
.th-inner.two-line{
  height: 21px;
  border-bottom: 1px solid #6dc2ed;
}
.fa-filter{
  font-size: 16px; line-height: 21px;
}
.table tbody td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}
.table tbody tr:nth-child(even){
/*  background: #fdfcfccc;*/
}
.table tbody tr td:nth-child(2){
  border-right: 1px solid #dadee0;
  margin-bottom: 100px;
/*  width: 110px;*/
}

.table tbody tr td:nth-child(n+2) > div{
/*  border-right: 1px solid #dadee0;*/
  overflow: hidden;
  position: relative;
  display: inline-block;
  padding-right: 5px;
  max-width: 500px;
  min-width: 100%;
}
.table tbody tr td:nth-child(n+2) > div:before{
  content: '';
  width: 15px;
  height: 30px;
  position: absolute;
  right: 0;
  background: linear-gradient(90deg,
    rgb(255 255 255 / 0%) 25%,
    rgb(255 255 255 / 69%) 50%,
    #ffffff 100%);
}
.table tbody tr td:first-child {
/*  border-radius: 8px 0 0 8px;*/
}
.table tbody tr td:last-child {
/*  border-radius: 0 8px 8px 0;*/
}
.table tr:hover {
  background-color: #fdfcfccc;
}
input[type=text] {
    border:none;
    background:none;
    outline:none;
    padding:0;
    font-size: 14px;
    font-weight: 100;
    color: #2c3e50;
}

.tab-el, .tab-el2 {
  border: 1px solid #f6f8fa;
  border-radius: 8px 0 0 8px;
  background-color: #f6f8fa;
  display: inline-block;
  padding: 10px 15px;
}

.tab-el {
}

.tab-el2 {
  margin: 0px 0px 15px 0px;
}
.field-icon{font-size: 18px;}
.field-icon .fa-check{color: green;}
.field-icon .fa-times{color: red;}

.ext-settings{
  overflow: hidden;
  width: 100%;
}
</style>
