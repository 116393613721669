<template>
  <Loader v-if="loader" />
  <div v-else>
    <div class="top-block">
        <div class="wrapper-hover">
          <i
              @click="changeShow"
              @keydown="changeShow"
              class="fa fa-cog top-block_mode" :class="{active: this.modeMain === 3}">
          </i>
          <span class="wrapper-hover_el">Подключение&nbsp;категорий</span>
      </div>
        <span class="top-block_text">Выбрано: {{cats.length}} из {{list.length}}</span>
    </div>
    <!--<InputTextShortNew class="input-search" />-->
    <div class="level" :class="`level-${el.level}`" v-for="(el, ind) in list" :key="ind">
      <!-- Расскоментарить, когда будет это свойство в серверной части -->
      <!-- <div v-show="el.level >= 2 && !el.notBorder.includes(2)" class="border border-2"></div>
      <div v-show="el.level >= 3 && !el.notBorder.includes(3)" class="border border-3"></div>
      <div v-show="el.level >= 4 && !el.notBorder.includes(4)" class="border border-4"></div>
      <div v-show="el.level >= 5 && !el.notBorder.includes(5)" class="border border-5"></div>
      <div v-show="el.level >= 6 && !el.notBorder.includes(6)" class="border border-6"></div>
      <div v-show="el.level >= 7 && !el.notBorder.includes(7)" class="border border-7"></div>
      <div v-show="el.level >= 8 && !el.notBorder.includes(8)" class="border border-8"></div>
      <div v-show="el.level >= 9 && !el.notBorder.includes(9)" class="border border-9"></div>
      <div v-show="el.level >= 10 && !el.notBorder.includes(10)" class="border border-10"></div> -->

      <div class="item" v-if="el.show"
        :class="{'ierarh-border': el.level > 0}">
        <div v-if="el.level > 0" class="squad-level"></div>
        <span class="el">
          <span :class="{'pointer': el.child || dynamic && el.cnt != 0 || el.articles}"
            class="box outter-big folder" style="float:left; display:block;"
            @click="toggleChild(ind)"
            @keydown.f9="toggleChild(0)">
            <template v-if="el.child || dynamic && el.cnt != 0">
              <i v-if="el.open" class="fa fa-folder-open" aria-hidden="true"></i>
              <i v-else class="fa fa-folder" aria-hidden="true"></i>
            </template>
            <span v-else-if="el.articles">
              <i v-if="!el.open" class="fa fa-folder" aria-hidden="true"></i>
              <i v-else class="fa fa-folder-open" aria-hidden="true"></i>
            </span>
            <span v-else class="empty-squad">
              <i class="fa fa-folder" aria-hidden="true"></i>
            </span>
          </span>
          <span class="box outter pointer" v-if="modeMain !== 3"
            @click="changeCats(ind)"
            @keydown.f9="changeCats(ind)"
            :class="{'active': el.chk}">
              <span class="text">{{ el.lbl }}</span>
              <span class="number">{{ el.cnt }}</span>
          </span>

          <span class="box outter category pointer" v-if="needCheckbox"
            :class="active">
            <Checkbox
              v-model="el.chk"
              @change="changeCats(ind, 1)"
              @click="saveCatsToStock"
              class="category"
            />
          </span>

          <span class="box outter pointer" v-if="modeMain === 3"
            @click="getSetNewProducts(el.val, el.lbl)"
            @keydown.f9="toggleChild(el.val)">
              <span class="text">{{ el.lbl }}</span>
              <span class="number">{{ el.cnt }}</span>
          </span>
        </span>
        <div v-if="el.open && el.articles" class="pack-articles level"
          :class="`level-${el.level + 1}`">
          <div class="article-one" v-for="(articul, ind2) in el.articles" :key="ind2">
            <div v-if="el.level > 0" class="squad-level"></div>
            <span class="box outter-big folder" style="float: left; display: block;">
              <span><i class="fa fa-cube" aria-hidden="true"></i></span>
            </span>
            <span class="box outter pointer"
              :class="{active: articul.chk}"
              :title="`${articul.lbl} [${articul.cnt}]`"
              @click="changeArticles(ind, ind2)"
              @keydown.f9="changeArticles(0)">
              <span class="text">{{articul.lbl}}</span>
              <span class="number">{{articul.cnt}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition name="slide-form">
    <ModalRight v-show="flagModalRight2"
      @close="flagModalRight2 = false;"
      :title="titleNewProducts">
      <FormOld v-if="flagModalRight2"
        :action="saveSetNewProducts"
        :fields="fieldsNewProducts"
        :title="anonsNewProducts"
        :btn="btnNewProducts"
        :params="formParams"
        :method="'post2'"
        @answerForm="answerFormOld"
      />
      <div class="form-answer" v-if="formAnswer">{{ formAnswer }}</div>
    </ModalRight>
  </transition>
  <div v-show="saveFlag">
    <a class="block-link" @click="flagModalRight = true;"
      @keydown.f9="flagModalRight = true;">Сохранить категории для выбранного склада</a>
  </div>
  <transition name="slide-form">
    <ModalRight v-show="flagModalRight"
      @close="flagModalRight = false;"
      :title="modalTitle">
      <ConfirmCrossDef v-if="flagModalRight"
        :inputtxt="confirm.txt"
        :btns="confirm.btns"
        closesec="100000"
        @ok="saveFlag = 0"
        @close="flagModalRight = false;"
        />
    </ModalRight>
  </transition>
</template>
<script>
import axios from 'axios';
import Checkbox from '@/components/v2/serj/form/fields/CheckDef.vue';
// import InputTextShortNew from '@/new/components/atoms/InputTextShort.vue';
import Loader from '@/components/loader/LoaderTreeSpin.vue';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import ConfirmCrossDef from '@/components/v2/cross/confirm/Def.vue';
import FormOld from '@/components/v2/serj/atoms/Form.vue';

export default {
  name: 'CategoryFunctional',
  // computed: {
  //   checkNotBorder() {
  //     if () {

  //     }
  //   }
  // },
  props: {
    stock: {
      type: Number,
      default: 0,
    },
    needCheckbox: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: '',
    },
    filter: {
      type: Array,
    },
    modeMain: {
      type: Number,
      default: 0,
    },
    oldModeMain: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ConfirmCrossDef,
    ModalRight,
    Checkbox,
    // InputTextShortNew,
    Loader,
    FormOld,
  },
  emits: ['transferCats', 'transferProds', 'changeShow', 'clearCatsProds'],
  data() {
    return {
      cats: [],
      articles: [],
      list: [],
      loader: true,
      saveFlag: 0,
      flagModalRight2: false,
      flagModalRight: false,
      modalTitle: 'Изменение привязанных категорий к складу',
      updateConfirm: '',
      confirm: {
        txt: 'У товаров, отключаемой категории (в том числе всех вложенных в неё категорий) для настраиваемого склада, параметр "Тип контроля" перейдёт в значение "Не контролируемые". При повторном подключенн категории все её товары перейдут в значение "Не настроенные", но предыдущие настройки вернутся, их необходимо будет настроить через "Массовый интерфейс настройки товаров. У товаров, подключаемой категории (в том числе всех вложенных в неё категорий) для настраиваемого склада, параметр "Тип контроля" перейдёт в значение "Не настроенный" (все ранее указанные для настройки това)',
        btns: [
          {
            tp: 'action',
            action: '/v2/category/list-by-stock-sett-save',
            param: {},
            txt: 'Подтвердить',
          }, {
            tp: 'close',
            txt: 'Отменить',
          },
        ],
      },
      flag: 0,
      chk: false,
      titleNewProducts: '',
      anonsNewProducts: '',
      btnNewProducts: '',
      fieldsNewProducts: [],
      setNewProducts: '/v2/category/get-set-new-products',
      saveSetNewProducts: '/v2/category/set-set-new-products',
      formParams: {},
      formAnswer: '',

      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // addBorder(level, open, ind){
    //   let index = ind;
    //   let els = 0;
    //   do {

    //     els += 1;
    //     index += 1;
    //   } while(level !== this.list[index].level);

    // },
    init() {
      // this.loader = true;
      this.$forceUpdate();

      if (this.modeMain === 3) {
        axios.get(this.action, {
          params: {
            stock: this.stock,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.list = res.data.list.list;

              this.list.forEach((el) => {
                if (el.chk) {
                  this.cats.push(el.val);
                }
              });

              this.loader = false;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
      if (this.modeMain !== 3) {
        axios.post(this.action, {
          stock: this.stock,
          mode: this.mode.val,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.list = res.data.list.list;
              this.loader = false;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
      this.$emit('clearCatsProds');
    },
    getSetNewProducts(cat, lbl) {
      this.formAnswer = '';
      this.formParams = {
        stock: this.stock,
        category: cat,
      };
      axios.get(this.setNewProducts, {
        params: {
          stock: this.stock,
          category: cat,
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.titleNewProducts = lbl;
            this.fieldsNewProducts = res.data.fields;
            this.anonsNewProducts = res.data.anons;
            this.btnNewProducts = res.data.btn;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });

      this.flagModalRight2 = true;
    },
    answerFormOld(answer) {
      this.formAnswer = answer.answer;
    },
    changeShow() {
      let modeMainChanged;

      if (this.modeMain === 1 || this.modeMain === 2) {
        modeMainChanged = 3;
      } else if (this.modeMain === 3 && this.oldModeMain === 2) {
        modeMainChanged = 2;
      } else if (this.modeMain === 3 && this.oldModeMain === 1) {
        modeMainChanged = 1;
      }

      this.$emit('changeShow', modeMainChanged);
    },
    changeCats(ind, checkbox = 0) {
      if (ind < 0) return;

      this.level = this.list[ind].level;
      this.flag = 0;
      if (checkbox === 0) {
        this.list[ind].chk = !this.list[ind].chk;
      }

      let indItem;
      let BreakException;
      const { chk } = this.list[ind];
      try {
        this.list.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            this.list[i].chk = true;
            this.cats.push(this.list[i].val);
          }

          if (this.flag === 2) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            this.list[i].chk = false;

            indItem = this.cats.indexOf(this.list[i].val);
            if (indItem !== -1) {
              this.cats.splice(indItem, 1);
            }
          }

          if (ind === i) {
            if (chk) {
              this.cats.push(this.list[i].val);
              this.flag = 1;
            } else {
              indItem = this.cats.indexOf(this.list[i].val);
              this.cats.splice(indItem, 1);
              this.flag = 2;
            }
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      this.cats = [];
      this.list.forEach((el) => {
        if (el.chk === true || el.chk === 1) {
          this.cats.push(el.val);
        }
      });

      this.uncheckAllArticles();
      this.articles = [];
      this.$emit('transferCats', this.cats);
    },
    saveCatsToStock() {
      if (this.saveFlag === 0) {
        this.saveFlag = 1;
      }

      let index;
      this.confirm.btns.forEach((el, ind) => {
        if (el.tp === 'action') {
          index = ind;
        }
      });
      this.confirm.btns[index].param = {
        stock: this.stock,
        cats: this.cats,
      };
    },
    toggleChild(ind) {
      if (this.list[ind].child === false) {
        if (this.list[ind].articles) {
          this.list[ind].open = !this.list[ind].open;
        }
        return;
      }
      this.level = this.list[ind].level;
      this.flag = 0;
      this.open = this.list[ind].open;
      let BreakException;
      try {
        this.list.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level === item.level) {
              throw BreakException;
            }
            // изменить show
            if (this.open === true) {
              if (this.list[i].level > this.level) {
                this.list[i].show = false;
                this.list[i].open = false;
              }
            } else if ((this.level + 1) === item.level) {
              this.list[i].show = true;
            }
          }

          if (ind === i) {
            this.flag = 1;
            // изменить open
            this.list[i].open = !this.list[i].open;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    changeArticles(i, i2) {
      const ev = this.list[i].articles[i2].ids;
      this.uncheckAll();

      if (this.list[i].articles[i2].chk === true) {
        this.list[i].articles[i2].chk = false;
        this.articles = this.articles.filter((item) => !ev.includes(item));
      } else {
        this.list[i].articles[i2].chk = true;
        this.articles = this.articles.concat(ev);
      }

      this.$emit('transferProds', this.articles);
    },
    uncheckAll() {
      this.list.forEach((el) => {
        el.chk = false;
      });
    },
    uncheckAllArticles() {
      this.list.forEach((el) => {
        if (el.articles) {
          el.articles.forEach((el2) => {
            el2.chk = false;
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.form-answer {
  padding: 10px;
  background: #2eb237;
  box-shadow: 0px 1px 1px #999;
  color: #fff;
  text-align: center;
}
.wrapper-hover {
  display: inline-flex;
}
.wrapper-hover:hover .wrapper-hover_el {
  display: inline-block;
  width: 200px;
  max-height: 20px;
  transition: width 0.6s linear;
}
.wrapper-hover_el {
  display: inline-block;
  overflow: hidden;
  width: 0px;
  max-height: 0px;
  margin-left: 4px;
}
.pointer {
  cursor: pointer;
}
.pack-articles{
  margin-top: 2px;
  margin-left: -5px;
}
.pack-articles.level-1{
  margin-left: 9px;
}
.pack-articles.level-3, .pack-articles.level-4,
.pack-articles.level-5, .pack-articles.level-6,
.pack-articles.level-7, .pack-articles.level-8,
.pack-articles.level-9{ padding-left: 14px; }
.pack-articles .article-one{
  white-space: nowrap;
  margin-bottom: 2px;
  /* margin-left: 5px; */
}

.article-one .box.outter:hover {
  background-color: #DCDDE1;
}

.article-one .box.outter.active{
  background-color: #d1edea;
}
.article-one .box .text{
  margin-right: 10px;
}
.article-one .box .number{
  border: 1px solid #d1d2d3;
  padding: 0px 4px;
  border-radius: 2px;
  color: #000;
  background-color: #fff;
}

.article-one .box-outter-big{
  display: block;
  z-index: 1;
  display: inline-block;
  position: relative;
}

.article-one > span, .article-one > div{
  display:inline-block; vertical-align:top;
}
.article-one .box.outter-big{
  position: relative;
  z-index: 1;
}

.top-block {
  height: 24px;
  margin-bottom: 4px;
  line-height: 24px;
  background-color: #f6f8fa;
  font-size: 11px;
  /* padding-left: 5px; */
  margin-top: -1px;
}
.top-block_mode {
  font-size: 14px;
  line-height: 14px;
  padding: 3px 0px;
  border-radius: 1px;
  background-color: #EDEFF1;
  color: #444;
  z-index: 1;
  border: 1px solid #dadee0;
  width: 20px;
  min-width: 20px;
  text-align: center;
}
.top-block_text {
  float:right;
  margin-right: 10px;
}
.el {
  white-space:nowrap;
  display: flex;
  align-items: center;
}
.el .box, .box {
  font-size: 12px;
  line-height: 14px;
  padding: 3px 0px;
  border-radius: 1px;
  background-color: #F6F8FA;
  color: #444;
  z-index: 1;
  border: 1px solid #dadee0;
  /* align-items: center; */
}
.el .box.category{
  padding: 0px 0px;
  border-radius: 1px;
  background-color: #F6F8FA;
  color: #444;
  z-index: 1;
  border: 1px solid #dadee0;
}
.el .box.folder, .box.folder  {
  width: 20px;
  min-width: 20px;
  text-align: center;
  background-color: #EDEFF1;
}
.el .box:hover {
  background-color:#DCDDE1;
}
.box.outter {
  margin-right: 3px;
  padding: 3px 5px;
}
.box.outter-big {
  margin-right: 2px;
}
.el .box .text {
  margin-right: 10px;
}
.el .box .number {
  border: 1px solid #d1d2d3;
  padding: 0px 4px;
  border-radius: 2px;
  color: #000;
  background-color: #fff;
}
.el .box.active {
  /* #a9bcc9 */
  background: #d1edea;
}
.fa-cog{
  color: #6e6e6e;
  cursor: pointer;
}
.fa-cog.active {
  color: #1a73e8;
}
.block-link {
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: inline-block;
  background-color: rgb(0, 120, 109);
  border: none;
  border-radius: 2px;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .5px;
  cursor: pointer;
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: rgb(232, 230, 227);
}
.block-link:hover{
  box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 11px 0px, rgba(0, 0, 0, 0.15) 0px 4px 15px 0px;
}
.block-link a {
  color: #000;
}
/* .empty-squad */
.squad-level{
  width: 14px;
  height: 12px;
  display: inline-block;
  vertical-align: top;
  margin-right: -5px;
  position: relative;
  float: left;
  left: 3px;
}
.squad-level:before{
  content: '';
  display: block;
  width: 126%;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: 95%;
  left: 0px;
  background-color: #a7a7a7;
  height: 1px;
}
.squad-level:after{
  content: '';
  display: block;
  width: 1px;
  position: absolute;
  top: -106%;
  left: 0px;
  height: 200%;
  background-color: #a7a7a7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.ierarh-border{
  /* border-left: 1px solid #000; */
}
.item{
 margin-bottom: 2px;
}
.item .fa-folder-open{
  margin-right: -2px;
}
.item .fa-folder{ color: #009688; }
.item .fa-folder-open{ color: #009688;}
.item .empty-squad .fa-folder{ color: gray;}
/* .squad{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #999;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  margin-right: 4px;
  float: left;
} */
.squad + span {
  display: block;
  margin-left: 24px;
  line-height: 22px;
}
.level {
  position:relative;
}
.level-1{padding-left: 5px}
.level-2{padding-left: 19px}
.level-3{padding-left: 33px}
.level-4{padding-left: 47px}
.level-5{padding-left: 61px}
.level-6{padding-left: 75px}
.level-7{padding-left: 89px}
.level-8{padding-left: 103px}
.level-9{padding-left: 117px}
.level-10{padding-left: 131px}

.border {
  content: '';
  display: block;
  width: 1px;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: -78%;
  height: 174%;
  background-color: #a7a7a7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.border-2 {left: 8px}
.border-3 {left: 28px}
.border-4 {left: 48px}
.border-5 {left: 68px}
.border-6 {left: 88px}
.border-7 {left: 108px}
.border-8 {left: 128px}
.border-9 {left: 148px}
.border-10 {left: 168px}
/* .level-2::after{
  content: '';
  display: block;
  width: 1px;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: -78%;
  left: -17px;
  height: 174%;
  background-color: #a7a7a7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
} */

.level-3::after{}
.level-4::after{}
.level-5::after{}
.level-6::after{}
.level-7::after{}
.level-8::after{}
.level-9::after{}
.level-10::after{}

.level-1 .squad + span,
.level-2 .squad + span,
.level-3 .squad + span,
.level-4 .squad + span,
.level-5 .squad + span,
.level-6 .squad + span,
.level-7 .squad + span,
.level-8 .squad + span,
.level-9 .squad + span,
.level-10 .squad + span{margin-left: 39px;}
</style>
