<template>
  <div class="resizable-container"
    @mousedown="startResize">
  </div>
</template>

<script>
export default {
  props: {
    blockWidth: {
      type: Number,
      default: 0,
    },
    maxWidth: {
      type: Number,
      default: 10000,
    },
    minWidth: {
      type: Number,
      default: 0,
    },
  },
  emits: ['changeWidth'],
  data() {
    return {
      initialMouseX: 0,
    };
  },
  methods: {
    startResize(event) {
      event.preventDefault(); // чтоб выделения текста не было
      this.initialMouseX = event.clientX;
      document.addEventListener('mousemove', this.resizeBlock);
      document.addEventListener('mouseup', this.stopResize);
    },
    resizeBlock(event) {
      const deltaX = event.clientX - this.initialMouseX;
      const changedWidth = this.blockWidth + deltaX;

      if (changedWidth < this.maxWidth && changedWidth > this.minWidth) {
        this.$emit('changeWidth', changedWidth);
        this.initialMouseX = event.clientX;
      }
      // else if (changedWidth > this.maxWidth) {
      //   this.$emit('changeWidth', this.maxWidth);
      // } else if (changedWidth < this.minWidth) {
      //   this.$emit('changeWidth', this.minWidth);
      // }
    },
    stopResize() {
      document.removeEventListener('mousemove', this.resizeBlock);
      document.removeEventListener('mouseup', this.stopResize);
    },
  },
};
</script>

<style>
.resizable-container {
  width: 10px;
  position: absolute;
  left: 0;
  z-index: 10;
  height: 100%;
  cursor: ew-resize;
}
</style>
