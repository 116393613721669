<template>
  <div class="ext-settings">
  </div>
  <div class="menu-contex-overlay" @click="hideContex"
    @keydown.f7="hideContex" v-if="showContexMenu"></div>
  <div class="menu-contex" v-if="showContexMenu"
    :style="{left: contexPosLeft + 'px', top: contexPosTop + 'px'}">
    <div v-for="(el, ind) in contexMenu" :key="ind" class='item'
      @keydown.f7="showForm(0)" @click="showForm(el.component, el.conf, ind)">
        <i class="fa icon_li" :class="el.icon"></i>{{el.title}}</div>
  </div>
  <transition name="fade">
    <LoaderTreeSpin v-if="loaderBody" />
    <table v-else-if="list.length" class="setka-sizes">
      <thead>
        <tr>
          <th class="block-head">Наименование</th>
          <th v-for="(el, ind) in head" :key="ind"
            class="block-head">{{el}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, ind) in list" :key="ind">
          <td class="block-head-line">
            {{el.name}}
          </td>
          <td v-for="(head, ind2) in head" :key="ind2">
            <div v-if="el.sizes[head]"
              class="block-cell no-empty"
              :class="el.sizes[head].color"
              @click="contextMenu(`${el.name}, ${head}`, el.sizes[head], $event)"
              @keydown.f7="contextMenu(`${el.name}, ${head}`, el.sizes[head].id, $event)"
              >
              {{el.sizes[head].val}}
            </div>
            <div v-else class="block-cell block-empty"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-empty" v-else>
      Выбранная категория не имеет общей структурной сетки, для вложенных категорий
    </div>
  </transition>
  <transition name="fade">
    <ModalRight v-show="flagModalRight"
      @close="flagModalRight = false;" :class="modalClass">
      <component :is="modalComponent" v-bind="modalConf"
        v-if="flagModalRight" @answer="chgList"></component>
    </ModalRight>
  </transition>
</template>
<script>
// import { mapGetters } from 'vuex';
import axios from 'axios';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import Form from '@/components/v2/cross/form/Form.vue';
import HorizontalMenuTab from '@/components/v2/cross/group/HorizontalMenuTab.vue';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import ConstructorDef1 from '@/components/v2/cross/ii/ConstructorDef.vue';

export default {
  name: 'ListSetka',
  components: {
    ModalRight,
    Form,
    HorizontalMenuTab,
    LoaderTreeSpin,
    Checkbox,
    ConstructorDef1,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    outerFilter: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['gotidsProdsOperations'],
  data() {
    return {
      showContexMenu: false,
      contexPosLeft: 0,
      contexPosTop: 0,
      contexProduct: 0,
      contexProductName: '',
      contexMenu: [
        {
          icon: 'fa-line-chart',
          title: 'График',
          type: 'ML',
          component: 'ConstructorDef1',
          conf: {
            title: '',
            pack: [
              {
                component: 'LabelsDef',
                conf: {
                  action: '/v2/product/status',
                },
              }, {
                component: 'ChartLiner',
                conf: {
                  action: '/v2/report/sku-graph',
                },
              }, {
                component: 'LabelsDef',
                conf: {
                  action: '/v2/product/item',
                },
              },
            ],
          },
        }, {
          icon: 'fa-cogs',
          title: 'Настроить',
          type: 'ML',
          component: 'HorizontalMenuTab',
          conf: {
            action: '/v2/product/set/',
            title: '',
          },
        },
      ],

      idsOperations: [],
      l_curr_stock: localStorage.getItem('stock'),
      flagModalRight: false,
      modalComponent: '',
      modalConf: '',
      modalClass: 'w50',
      currIndex: -1,

      filter: {},
      list: [],
      head: [],
      tablefilter: [],
      tableMenu: {},
      showFilter: false,

      currParam: {},
      currProduct: {},
      // currIndex: [],

      pagination: {},

      modeView: 0,
      tabsView: [],

      answerText: '',
      errText: '',
      errors: [],

      loader: true,
      loaderFilter: true,
      loaderBody: true,

      cntAll: 0,

      viewGroup: {},
      loadModal: false,
      showModalGroup: false,
      // End iFaceProduct
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loaderBody = true;

      axios.post(this.action, { filter: this.outerFilter })
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list.items;
            this.head = res.data.list.head;
            this.tableMenu = res.data.list.menu;
            // this.pagination = res.data.list.pagination;
          }
          this.loaderBody = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    contextMenu(name, el, ev) {
      this.contexPosLeft = ev.clientX;
      this.contexPosTop = ev.clientY;

      this.contexProduct = el.id;
      this.contexProductName = name;
      this.showContexMenu = true;
    },
    hideContex() {
      this.showContexMenu = false;
    },
    showForm(component, conf, ind) {
      conf.title = this.contexProductName;
      if (conf.action) {
        conf.action = `${conf.action}?stock=${this.stock}&prod=${this.contexProduct}`;
      }

      if (conf.pack) {
        conf.pack[0].conf.action = `${conf.pack[0].conf.action}?stock=${this.outerFilter.stock}&prod=${this.contexProduct}`;
        conf.pack[1].conf.action = `${conf.pack[1].conf.action}?stock=${this.outerFilter.stock}&prod=${this.contexProduct}`;
        conf.pack[2].conf.action = `${conf.pack[2].conf.action}?stock=${this.outerFilter.stock}&prod=${this.contexProduct}`;
      }

      this.modalClass = 'w100';
      this.modalComponent = component;
      this.modalConf = conf;
      this.currIndex = ind;
      this.flagModalRight = true;
      this.showContexMenu = false;
    },
    sort(ind) {
      if (!this.head[ind].sort.show || ind < 1) {
        return;
      }
      if (this.head[ind].sort.active) {
        if (this.head[ind].sort.val === 0) {
          this.head[ind].sort.val = 1;
        } else {
          this.head[ind].sort.val = 0;
        }
        this.loadList();
      } else {
        let i = this.head.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.head[ind].sort.active = 1;
          } else {
            this.head[i].sort.active = 0;
          }
        }
        this.loadList();
      }
    },
    chgSubFilter() {
      this.loadList(0);
    },
    loadList() {
      axios.post(this.action, {
        filter: this.outerFilter,
        extfilter: this.tablefilter,
        sort: this.head,
        page: this.pagination.page,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list.items;
          }
          this.loaderBody = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    pagLoad(el) {
      this.loaderBody = true;
      if (this.pagination.page === el.lbl) { return; }
      this.pagination.page = el.lbl;

      this.loadList();
      /*
      setTimeout(() => {
        this.loadList();
      }, 100);
      */
    },
    act(elem) {
      if (elem.type === 0) {
        this.$router.push(elem.act);
      }
    },
    test(data) {
      this.loader = data;
    },
    removeTr(index) {
      this.tbody.splice(index, 1);
    },
    chgList(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'ind')) {
        this.currIndex = data.ind;
      }
      if (data.act === 'chg') {
        this.list.splice(this.currIndex, 1, data.elem);
      } else if (data.act === 'add') {
        this.list.unshift(data.elem);
      }
    },
  },
};
</script>

<style scoped>
.menu-contex-overlay{
  opacity: 0.5;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #aaa;
  z-index: 998;
}
.menu-contex{
  position: absolute;
  left: 0px;
  top: 0px;
  transition: left 0.3s, top 0.3s;
  z-index: 999;
  list-style-type: none;
  padding: 8px 0px;
  margin: 0px 0px;
  background-color: #fff;

  animation: burgerAnimation 0.4s;
  border: 1px solid #dadee0;
  box-shadow: 0px 0.5rem 1rem 0px rgb(28 36 40 / 18%);
  border-radius: 0px 5px 16px 0px / 0px 5px 18px 0px;
}
.menu-contex .item{
  font-weight: normal;
  padding: 12px 20px;
  font-size: 12px;
  cursor: pointer;
}
.menu-contex .item:hover{
  background-color: #eaeef1;
}
.menu-contex .item .icon_li{
  margin-right: 12px;
}
.text-empty{
  padding-top: 28px;
  background: #f0dcdc;
  height: calc(100% - 27px);
  text-align: center;
  border: 1px solid #d1d2d3;
}
.ext-settings {
  overflow: hidden;
  width: 100%;
  height: 26px;
}
.block-head{
  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;
  /* padding: 0; */
  margin: 0;
}

.block-head-line{
  position: sticky;
  left: 0;
  background-color: #f6f8fa;
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
  border-right: 1px solid #dadee0;
  overflow: hidden;
  display: inline-block;
  padding-right: 5px;
  max-width: 500px;
  min-width: 100%;
  box-sizing: border-box;
}

.block-cell{
  padding: 6px 8px;
  text-align: center;
  font-size: 14px;
}
.block-cell.no-empty{
  cursor: pointer;
  transition: 0.5s opacity;
}
.block-cell.no-empty:hover{
  opacity: 0.8;
}
table.setka-sizes{
  border-spacing: 0px;
  border-collapse: collapse;
  margin-bottom: 40px;
}
table tbody td{
  max-width: 45px;
  min-width: 45px;
}

.setka-sizes .progress-bar-success{ background: #a8e0a8;}
.setka-sizes .progress-bar-danger{ background: #fbb4b2;}
.setka-sizes .progress-bar-info{ background: #9cd8ff; }
.setka-sizes .progress-bar-warning { background: #ffdeb0;}
.setka-sizes .progress-bar-black { background: #b0b0b0; }

.block-empty{
  width: 100%;
  height: 28px;
  padding: 0px;
  background: #ddd;
}

table thead tr th:last-child {
  /* border-radius: 0 8px 8px 0; */
  width: 100%;
}
table tr td:last-child, table tr td:last-child > div{
  text-align:left;
}

body table.table .progress.progress-striped{
  min-width: 180px;
  max-width: 260px;
}
table.table {
  border-spacing: 0px;
  border-collapse: collapse;
}
.table{
   background: #fff;
}
.container-up-table {
  display: flex;
  position: sticky;
  align-items: flex-end;
  flex-direction: column;
}
tr.disabled td{
  opacity: 0.3;
}
tr.disabled td:first-child{
  opacity: 1;
}

/*
table.table {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s;
}
table.table:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
*/

.links {
}
.links_el {
}
.select-checkbox {
}
.hover {
  cursor: pointer;
}
.i-color {
  color: #b4b8bb;
}
.fa-share {
  transform: rotate(180deg);
  transform:scale(1, -1);
/*  transition: width 0.1s ease-out;*/
}

.slide-form-leave-active {
  transition: width 0.1s ease-in;
}
.slide-form-enter-from, .slide-form-leave-to{
  width: 200%;
}
.slide-form-enter-to, .slide-form-leave-from{
  width: 100%;
}
.instrumental-new {
  display: flex;
  position: sticky;
  top: 50px;
  width: 100%;
  justify-content: flex-end;
}
.instrumental-new .btn-add-new {
    background: #f6f8fa;
    font-size: 13px;
    padding: 6px;
    display: inline-block;
    cursor: pointer;
    top: 1px;
    border: 1px solid #dadee0;
    position: relative;
    transition: all 0.3s;
}
.instrumental-new .btn-add-new:hover {
    border: 1px solid #6dc2ed;
}
.wrapper-pagination {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  left: 0px;
  bottom: -2px;
  width: 100%;
}
.pagination-new {
  display: flex;
/*  background: #f6f8fa;*/
/*  border-radius: 7px;*/
/*  padding: 5px 5px;*/
/*  border: 1px solid #dadee0;*/
}
/* Begin */
.pagination-item {
  list-style-type: none;
  display: inline-block;
  border-top: 1px solid #d7dadb;
  border-right: 1px solid #d7dadb;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 14px;
  color: #8b969c;
  background-color: white;
  cursor: pointer;
}
.pagination-item:first-child {
  border-left: 1px solid #d7dadb;
}

.pagination-item:hover {
  background-color: #DCDDE1;
  border-top: 1px solid #DCDDE1;
  border-right: 1px solid #DCDDE1;
}
.pagination-item.active {
  background-color: #bcede8;
  border-top: 1px solid #bcede8;
  border-right: 1px solid #bcede8;
  color: #333;
}

.pagination-item.first-number {
  border-left: 1px solid #d7dadb;
}

.pagination-link {
  padding: 1.1em 1.6em;
  display: inline-block;
  text-decoration: none;
  color: #8b969c;

  transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(20deg) skewY(0deg);
}
/* End */
.pagination-new .pag-el-new.active {
/*    background-color: #44b752;*/
/*    border: 1px solid #2c9539;*/
  border: 1px solid #6dc2ed;
  background-color: #f6f4f4;
/*  background-color: #0000003d;*/
}
.pagination-new .pag-el-new {
/*    vertical-align: top;*/
    /* background-color: #749be5; */
    /* border: 1px solid #4c7fe1; */
    /* color: #fff; */
    cursor: pointer;
    padding: 12px 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 100px;
    transition: all 0.3s;
    border: 1px solid #f6f8fa;
}
.pagination-new .pag-el-new:hover {
  border: 1px solid #6dc2ed;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.table-filter .short-input-filter{
  max-width: 100%
}
.table {
/*  width: 100%;*/
/*  border-left: 1px solid #dadee0;*/
  border-collapse: collapse;
/*  border-spacing: 0px;*/
}
.table thead {
  position: sticky;
  top: 0px;
  z-index: 1;
}
.table thead th {
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;

  padding: 0;
  margin: 0;
  vertical-align: top;
}
.table thead tr:last-child th{
  /* border-bottom: 1px solid #6dc2ed; */
}
.table thead tr:nth-child(n+2) th:first-child{
/*  border-right: 1px solid #dadee0;*/
  text-align: center;
}
.table thead tr th:first-child {
  border-top: none;
  border-left: none;
  width: 12px;
/*  border-radius: 8px 0 0 8px;*/

}
.table thead tr th:last-child {
/*  border-radius: 0 8px 8px 0;*/
  width: 100%;
}
.th-inner{
  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;

}
.th-inner.two-line{
  height: 21px;
  border-bottom: 1px solid #6dc2ed;
}
.fa-filter{
  font-size: 16px; line-height: 21px;
}
.table tbody td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}
.table tbody tr:nth-child(even){
/*  background: #fdfcfccc;*/
}
.table tbody tr td:nth-child(2){
  border-right: 1px solid #dadee0;
  margin-bottom: 100px;
/*  width: 110px;*/
}

.table tbody tr td:nth-child(n+2) > div{
/*  border-right: 1px solid #dadee0;*/
  overflow: hidden;
  position: relative;
  display: inline-block;
  padding-right: 5px;
  max-width: 500px;
  min-width: 100%;
}
.table tbody tr td:nth-child(n+2) > div:before{
  content: '';
  width: 15px;
  height: 30px;
  position: absolute;
  right: 0;
  background: linear-gradient(90deg,
    rgb(255 255 255 / 0%) 25%,
    rgb(255 255 255 / 69%) 50%,
    #ffffff 100%);
}
.opacity50{opacity: 0.5;}
.table tbody tr td:first-child {
/*  border-radius: 8px 0 0 8px;*/
}
.table tr:hover {
  background-color: #fdfcfccc;
}
input[type=text] {
    border:none;
    background:none;
    outline:none;
    padding:0;
    font-size: 14px;
    font-weight: 100;
    color: #2c3e50;
}

.tab-el, .tab-el2 {
  border: 1px solid #f6f8fa;
  border-radius: 8px 0 0 8px;
  background-color: #f6f8fa;
  display: inline-block;
  padding: 10px 15px;
}

.tab-el {
}

.tab-el2 {
  margin: 0px 0px 15px 0px;
}
.field-icon{font-size: 18px;}
.field-icon .fa-check{color: green;}
.field-icon .fa-times{color: red;}

.ext-settings{
  overflow: hidden;
  width: 100%;
}
</style>
