<template>
  <transition name="fade">
    <Loader v-if="spiner" />
    <div v-else style="padding-left: 14px">

      <div class="left-float">
        <div class="collapse-info" v-if="info.info">
          <div class="collapse-btn">
            <i class="fa fa-list-alt" aria-hidden="true"></i>
          </div>
          <transition name="fade">
          <div class="collapse-content" style="">
            <div class="group-item" v-if="info.info">
              <div class="column-item" v-for="(el, ind) in info.info.list" :key="ind" >
                <strong>{{el[0]}}</strong>: <span>{{el[1]}}</span>
              </div>
            </div>
          </div>
          </transition>
        </div>

        <div class="collapse-info" v-if="info.info2">
          <div class="collapse-btn">
            <i class="fa fa-truck" aria-hidden="true"></i>
          </div>
          <transition name="fade">
          <div class="collapse-content">
             <div class="group-item" v-if="info.info2">
              <table class="hover-info">
                <tr>
                  <th v-for="(el, ind) in info.info2.head" :key="ind" >
                    {{el}}
                  </th>
                </tr>
                <tr v-for="(el, ind) in info.info2.list" :key="ind"
                  :class="{main: el[0] == 1}" >
                  <td v-for="(el2, ind2) in el" :key="ind2" class="gray" >
                    {{el2}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          </transition>
        </div>

        <div class="collapse-info" v-if="info.info4 && info.info4.length">
          <div class="collapse-btn">
            <i class="fa fa-wrench" aria-hidden="true"></i>
          </div>
          <transition name="fade">
          <div class="collapse-content">
            <div class="group-item" v-if="info.info">
              Данные о комплектующих
            </div>
          </div>
          </transition>
        </div>
      </div>

      <div class="group-item mini" v-if="info.info3">
        <div class="one-line-item" :class="el.color"
          v-for="(el, ind) in info.info3" :key="ind" >
          <span v-if="el.type == 1" :title="el.title">{{el.icon}}: {{el.val}}</span>
          <span v-if="el.type == 0">
            <i :title="el.title" :class="el.icon"></i>: {{el.val}}
          </span>
        </div>
      </div>

      <!-- <div class="round-3d"> -->
        <!-- <i class="fa fa-film" aria-hidden="true"></i> -->
        <!-- <div class="wb-name">О нас</div> -->
      <!-- </div> -->
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

import Loader from '@/components/v2/cross/loader/Loader.vue';

export default {
  name: 'LabelsDef',
  components: {
    Loader,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      info: [],

      errors: [],
      spiner: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.action) return;
      axios.get(this.action)
        .then((res) => {
          this.info = res.data;
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.left-float{
  float: left;
}
.collapse-info{
  position: relative;
  float: left;
  margin-right: 5px;
}
.collapse-btn{
  padding: 3px 3px 2px;
  background-color: #deefde;
  border: 1px solid #b9d1b9;
  cursor: help;
  transition: background-color 0.5s, border-color 0.5s;
}
.collapse-content{
  display: none;
  position: absolute;
  top: 28px;
  left: 0px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.collapse-info:hover .collapse-btn{
  background-color: #ccf3cca;
  border-color: #82a182;
}
.collapse-info:hover .collapse-content{
  display: block;
}
.group-item{
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 16px;
}
.group-item.mini{
  overflow: hidden;
  position: relative;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.group-item.mini .one-line-item{
  font-size: 12px;
  padding: 4px 5px;
  float: right;
  margin-bottom: 0px;
}

.one-line-item{
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  padding: 6px 9px;
  background-color: #f6f6f6;
}
.one-line-item.black{
  color: #000000;
  background: #cfcfcf;
  border-color: #707070;
}
.one-line-item.red{
  color: #a91a1a;
  background: #ffeaea;
  border-color: #c9b1b1;
}
.one-line-item.yellow{
  color: #8e9717;
  background: #fffdea;
  border-color: #c8c9b1;
}
.one-line-item.green{
  color: #23a91a;
  background: #ecffea;
  border-color: #b1c9b2;
}
.one-line-item.blue{
  color: #1a5aa9;
  background: #eaf4ff;
  border-color: #b1bbc9;
}
.one-line-item.new{
  color: #651aa9;
  background: #efeaff;
  border-color: #bcb1c9;
}

.column-item{
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  border: 1px solid #ddd;
  padding: 3px 4px;
  background-color: #f6f6f6;
  white-space: nowrap;
}
table.hover-info{
  border-spacing: 0px;
  border-collapse: collapse;
}
table.hover-info th{
  /*
  white-space: nowrap;
  padding: 6px 9px;
  background-color: #f6f6f6;
  border: 1px solid #ddd;
  */
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  vertical-align: top;
  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;
}
td.gray{
  /*
  padding: 6px 9px;
  background-color: #f6f6f6;
  border: 1px solid #ddd;
  */
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}
tr.main td{
  background-color: #efeaff;
  border-color: #bcb1c9;
  color: #651aa9;
}
.flex1{
  display: flex;
  flex-wrap: wrap;
}
.m-plate{
  white-space: nowrap;
}
.round-3d {
  transition: background 1s;
  margin-bottom: 15px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  background: #fff;
  background: radial-gradient(circle at 97px 0px, #ffffff, #f0f0f0, #aaaaaa);
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, .3);
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: center;
}
.round-3d {
  background: radial-gradient(circle at 47px 92px, #c08bff, #604b75, #6c5087);
}
.round-3d i {
  display: block;
  text-align: center;
  margin-top: 4px;
  color: #ac9fcd38;
  font-size: 60px;
  line-height: 90px;
  position: absolute;
  left: 50%;
  top: 0px;
  margin-left: -32px;
}
.wb-name {
  z-index: 1;
  position: relative;
  text-align: center;
  color: #4e368c;
  line-height: 20px;
  transition: color 0.5s;
}
.round-3d .wb-name {
  color: #fff;
}

</style>
