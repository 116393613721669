<template>
  <div class="wb-input-text">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <label :for="id">
    <input
      :id="id"
      :class="{'error-input': err, 'req': req}"
      type="text"
      :placeholder="def"
      :value="modelValue"
      autocomplete="off"
      @input="onInput"
      @focus="onFocus" />
    </label>
    <transition>
      <span class="error" v-if="err">
        <i class="close" @click="onClose" @keydown.x="onClose">&#10006;</i>
        {{err}}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InputText1',
  props: ['lbl', 'def', 'err', 'req', 'ind', 'modelValue'],
  emits: ['update:modelValue', 'outError'],
  data() {
    return {
      show_ext_lbl: false,
      id: null,
    };
  },
  mounted() {
    this.id = this.$.uid;
  },
  methods: {
    onInput(event) {
      if (event.target.value.length !== 0) {
        this.$emit('outError', this.ind);
      }
      this.$emit('update:modelValue', event.target.value);
    },
    onClose() {
      console.log(this.ind);
      this.$emit('outError', this.ind);
    },
  },
};
</script>

<style scoped>
  .wb-input-text{
    position: relative;
    margin-left: 0px;
    font-size: 14px;
    color: #444;
    font-weight: 400;
  }
  input {
    display: inline-block;
    width: 100%;
    border-radius: 0 4px 4px 0px;
    border: none;
    /* border-left: 4px solid #aaa; */
    line-height: 18px;
    outline: none;
    box-sizing: border-box;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 8px 10px;
  }
  input:hover{background:#efefef}
  .ext-label{
    position: absolute;
    top: -10px;
    left: 8px;
    display: block;
    border: 1px solid #aaa;
    padding: 0px 6px;
    font-size: 12px;
    background: #efefef;
  }
  .label{
    position: absolute;
    background: #a9bcc9;
    left: 8px;
    top: -15px;
    font-size: 14px;
    line-height: 16px;
    padding: 2px 12px;
    border-radius: 2px;
    color: #fff;
    text-shadow: 0 1px 0 rgba(87, 87, 87, 0.5);
  }
  .req{border-left: 4px solid #2567f3;}
  .error{
    float: right;
    position: absolute;
    display: block;
    top: 34px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    background: #f3c9c9;
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
    padding: 3px 11px 2px;
    color: #b33d3d;
  }
  .error-input{
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
  }
  .error-input::-webkit-input-placeholder {color: #e57272;}
  .error-input:-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input::-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input:-ms-input-placeholder {color: #e57272;}
  .error-input::-ms-input-placeholder {color: #e57272;}
  .error-input::placeholder {color: #e57272;}
  .error .close{
    float: right;
    cursor: pointer;
    font-style: normal;
    margin-right: -11px;
    margin-top: 0px;
    display: block;
    width: 18px;
    height: 18px;
    text-align: center;
  }
</style>
