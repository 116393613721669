<template>
  <div class="wrp-g">
    <Loader v-if="loader" />
    <div v-else>
      <i v-if="setCats"
        @click="chgList"
        @keydown="chgList"
        class="fa fa-cog" :class="{active: fSetCats}"></i>
      <div v-else class="place-top">Выбрано: {{cats.length}} из {{list.length}}</div>
      <div class="level" :class="`level-${el.level}`" v-for="(el, ind) in list" :key="ind">
        <div class="item" v-if="el.show"
          :class="{'ierarh-border': el.level > 0}">
          <div v-if="el.level > 0" class="squad-level"></div>
          <span class="el">
            <span :class="{'pointer': el.child || dynamic && el.cnt != 0 || el.articles}"
              class="box outter-big folder" style="float:left; display:block;"
              @click="toggleChild(ind)"
              @keydown.f9="toggleChild(0)">
              <template v-if="el.child || dynamic && el.cnt != 0">
                <i v-if="el.open" class="fa fa-folder-open" aria-hidden="true"></i>
                <i v-else class="fa fa-folder" aria-hidden="true"></i>
              </template>
              <span v-else-if="el.articles">
                <i v-if="!el.open" class="fa fa-folder" aria-hidden="true"></i>
                <i v-else class="fa fa-folder-open" aria-hidden="true"></i>
              </span>
              <span v-else class="empty-squad">
                <i class="fa fa-folder" aria-hidden="true"></i>
              </span>
            </span>
            <span class="box outter pointer" v-if="modeMain !== 3"
              @click="changeCats(ind)"
              @keydown.f9="changeCats(ind)"
              :class="{'active': el.chk}"
              :title="`${el.lbl} [${el.cnt}]`"
            >
              <span class="text">{{ el.lbl }}</span>
              <span class="number">{{ el.cnt }}</span>
            </span>
            <span class="box outter category pointer" v-if="needCheckbox">
              <Checkbox
                v-model="el.chk"
                @change="changeCats(ind, 1)"
                @click="saveCatsToStock"
                class="category"
              />
            </span>
            <span class="box outter pointer" v-if="modeMain === 3"
              @click="getSetNewProducts(el.val, el.lbl)"
              @keydown.f9="toggleChild(el.val)"
            >
              <span class="text">{{ el.lbl }}</span>
              <span class="number">{{ el.cnt }}</span>
            </span>
          </span>
          <div v-if="el.open && el.articles" class="pack-articles level"
            :class="`level-${el.level + 1}`">
            <div class="article-one" v-for="(articul, ind2) in el.articles" :key="ind2">
              <div v-if="el.level > 0" class="squad-level"></div>
              <span class="box outter-big folder" style="float: left; display: block;">
                <span><i class="fa fa-cube" aria-hidden="true"></i></span>
              </span>
              <span class="box outter pointer"
                :class="{active: articul.chk}"
                :title="`${articul.lbl} [${articul.cnt}]`"
                @click="changeArticles(ind, ind2)"
                @keydown.f9="changeArticles(0)">
                <span class="text">{{articul.lbl}}</span>
                <span class="number">{{articul.cnt}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide-form">
      <ModalRight v-show="flagModalRight2"
        @close="flagModalRight2 = false;"
        :title="titleNewProducts">
        <FormOld v-if="flagModalRight2"
          :action="saveSetNewProducts"
          :fields="fieldsNewProducts"
          :title="anonsNewProducts"
          :btn="btnNewProducts"
          :params="formParams"
          :method="'post2'"
          @answerForm="answerFormOld"
        />
        {{ formAnswer }}
      </ModalRight>
    </transition>
    <div v-show="saveFlag">
      <a class="block-link" @click="flagModalRight = true;"
        @keydown.f9="flagModalRight = true;">Сохранить категории для выбранного склада</a>
    </div>
    <transition name="slide-form">
      <ModalRight v-show="flagModalRight"
        @close="flagModalRight = false;"
        :title="modalTitle">
        <ConfirmCrossDef v-if="flagModalRight"
          :inputtxt="confirm.txt"
          :btns="confirm.btns"
          closesec="100000"
          @ok="saveFlag = 0"
          @close="flagModalRight = false;"
          />
      </ModalRight>
    </transition>
  </div>
</template>
<script>
import axios from 'axios';
// import InputTextShortNew from '@/new/components/atoms/InputTextShort.vue';
import FormOld from '@/components/atoms/Form.vue';
import Loader from '@/components/loader/LoaderTreeSpin.vue';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import ConfirmCrossDef from '@/components/v2/cross/confirm/Def.vue';
import Checkbox from '@/components/v2/serj/form/fields/CheckDef.vue';

export default {
  name: 'IerarhDef',
  props: {
    stock: {
      type: Number,
      default: 0,
    },
    onlyOne: {
      type: Boolean,
      default: false,
    },
    // modeMain
    setCats: {
      type: Boolean,
      default: false,
    },
    // mode
    typeControl: {
      type: Number,
      default: 5,
    },
    needCheckbox: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: '',
    },
    filter: {
      type: Array,
    },
    modeMain: {
      type: Number,
      default: 0,
    },
    oldModeMain: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ConfirmCrossDef,
    ModalRight,
    Checkbox,
    // InputTextShortNew,
    Loader,
    FormOld,
  },
  emits: ['transferCats', 'transferProds', 'chgList', 'clearCatsProds'],
  data() {
    return {
      fSetCats: false,
      cats: [],
      articles: [],
      list: [],
      loader: false,
      saveFlag: 0,
      flagModalRight2: false,
      flagModalRight: false,
      modalTitle: 'Изменение привязанных категорий к складу',
      updateConfirm: '',
      confirm: {
        txt: 'Подтвердите действие удаления категорий. После подтверждения, категория перестанет существовать, а все товары привязанные к категории будут существовать как отдельные единицы, с настройками существовавшими до того ',
        btns: [
          {
            tp: 'action',
            action: '/v2/category/list-by-stock-sett-save',
            param: {},
            txt: 'Подтвердить',
          }, {
            tp: 'close',
            txt: 'Отменить',
          },
        ],
      },
      flag: 0,
      chk: false,
      titleNewProducts: '',
      anonsNewProducts: '',
      btnNewProducts: '',
      fieldsNewProducts: [],
      setNewProducts: '/v2/category/get-set-new-products',
      saveSetNewProducts: '/v2/category/set-set-new-products',
      formParams: {},
      formAnswer: '',

      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loader = true;
      // this.$forceUpdate();
      if (this.fSetCats) {
        this.loadSetCats();
      } else {
        this.loadActiveCats();
      }
      // this.$emit('clearCatsProds');
    },
    loadActiveCats() {
      axios.post(this.action, {
        stock: this.stock,
        mode: this.typeControl,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list.list;

            const catsetkaID = localStorage.getItem('catsetka');
            if (this.onlyOne) {
              if (catsetkaID === null) {
                this.currFirstBot();
              } else {
                this.findIndByID(catsetkaID);
              }
            } else {
              this.$emit('transferCats', this.cats);
              // this.findIndByID(catsetkaID);
            }

            this.loader = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    loadSetCats() {
      axios.get(this.action, {
        params: {
          stock: this.stock,
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list.list;

            this.list.forEach((el) => {
              if (el.chk) {
                this.cats.push(el.val);
              }
            });

            this.loader = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getSetNewProducts(cat, lbl) {
      this.formAnswer = '';
      this.formParams = {
        stock: this.stock,
        category: cat,
      };
      axios.get(this.setNewProducts, {
        params: {
          stock: this.stock,
          category: cat,
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.titleNewProducts = lbl;
            this.fieldsNewProducts = res.data.fields;
            this.anonsNewProducts = res.data.anons;
            this.btnNewProducts = res.data.btn;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });

      this.flagModalRight2 = true;
    },
    answerFormOld(answer) {
      this.formAnswer = answer.answer;
    },
    chgList() {
      this.fSetCats = !this.fSetCats;

      this.$emit('chgList', this.fSetCats);
    },
    uncheckAll() {
      this.list.forEach((el) => {
        el.chk = false;
      });
    },
    uncheckAllArticles() {
      this.list.forEach((el) => {
        if (el.articles) {
          el.articles.forEach((el2) => {
            el2.chk = false;
          });
        }
      });
    },
    currFirstBot() {
      let prevIndex = -1;
      let BreakException;
      try {
        let prevLevel = -1;
        this.list.forEach((el, i) => {
          if (prevLevel !== -1 && prevLevel <= el.level) {
            throw BreakException;
          }
          prevLevel = el.level;
          prevIndex = i;
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      this.cats = [];
      this.list[prevIndex].chk = true;
      this.cats.push(this.list[prevIndex].val);
      localStorage.setItem('catsetka', this.list[prevIndex].val);
      this.$emit('transferCats', this.cats);
    },
    findIndByID(id) {
      let ind = -1;
      this.list.forEach((el, i) => {
        if (el.val === parseInt(id, 10)) {
          ind = i;
        }
      });

      if (ind !== -1) {
        this.list[ind].chk = true;
        this.cats = [];
        this.cats.push(this.list[ind].val);
        this.$emit('transferCats', this.cats);
      } else {
        this.currFirstBot();
      }
    },
    changeCats(ind, checkbox = 0) {
      if (ind < 0) return;

      // вынести в отдельную функцию, бизнес-логика, однако
      if (this.onlyOne === 1 || this.onlyOne === true) {
        this.cats = [];
        this.uncheckAll();
        if (this.list[ind]) {
          localStorage.setItem('catsetka', this.list[ind].val);
          this.list[ind].chk = true;
          this.cats.push(this.list[ind].val);
        }
        this.$emit('transferCats', this.cats);
        return;
      }

      this.level = this.list[ind].level;
      this.flag = 0;
      if (checkbox === 0) {
        this.list[ind].chk = !this.list[ind].chk;
      }

      // let indItem;
      let BreakException;
      const { chk } = this.list[ind];
      try {
        this.list.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            this.list[i].chk = true;
          }

          if (this.flag === 2) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            this.list[i].chk = false;
          }

          if (ind === i) {
            if (chk) {
              this.flag = 1;
            } else {
              this.flag = 2;
            }
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      this.cats = [];
      this.list.forEach((el) => {
        if (el.chk === true || el.chk === 1) {
          this.cats.push(el.val);
        }
      });

      this.uncheckAllArticles();
      this.articles = [];
      this.$emit('transferCats', this.cats);
    },
    saveCatsToStock() {
      if (this.saveFlag === 0) {
        this.saveFlag = 1;
      }

      let index;
      this.confirm.btns.forEach((el, ind) => {
        if (el.tp === 'action') {
          index = ind;
        }
      });
      this.confirm.btns[index].param = {
        stock: this.stock,
        cats: this.cats,
      };
      // this.updateConfirm = JSON.stringify(this.confirm.btns[index].param);
    },
    toggleChild(ind) {
      if (this.list[ind].child === false) {
        if (this.list[ind].articles) {
          this.list[ind].open = !this.list[ind].open;
        }
        return;
      }
      this.level = this.list[ind].level;
      this.flag = 0;
      this.open = this.list[ind].open;
      let BreakException;
      try {
        this.list.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level === item.level) {
              throw BreakException;
            }
            // изменить show
            if (this.open === true) {
              if (this.list[i].level > this.level) {
                this.list[i].show = false;
                this.list[i].open = false;
              }
            } else if ((this.level + 1) === item.level) {
              this.list[i].show = true;
            }
          }

          if (ind === i) {
            this.flag = 1;
            // изменить open
            this.list[i].open = !this.list[i].open;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    changeArticles(i, i2) {
      const ev = this.list[i].articles[i2].ids;
      this.uncheckAll();

      if (this.list[i].articles[i2].chk === true) {
        this.list[i].articles[i2].chk = false;
        this.articles = this.articles.filter((item) => !ev.includes(item));
      } else {
        this.list[i].articles[i2].chk = true;
        this.articles = this.articles.concat(ev);
      }

      this.$emit('transferProds', this.articles);
    },
  },
};
</script>
<style scoped>
.wrp-g{
  overflow-x: auto;
}
.wrp-g.h93{
  height: calc(100vh - 93px);
  padding-left: 3px;
}
.place-top{
  height: 24px;
  margin-bottom: 6px;
  line-height: 24px;
  background-color: #f6f8fa;
  font-size: 11px;
  padding-left: 5px;
}
.pointer {
  cursor: pointer;
}
.pack-articles{
  margin-top: 2px;
  /* margin-left: -5px; */
}
.pack-articles.level-3, .pack-articles.level-4,
.pack-articles.level-5, .pack-articles.level-6,
.pack-articles.level-7, .pack-articles.level-8,
.pack-articles.level-9{ padding-left: 14px; }
.pack-articles .article-one{
  white-space: nowrap;
  margin-bottom: 2px;
}

.article-one .box.outter:hover {
  background-color: #DCDDE1;
}

.article-one .box.outter.active{
  background-color: #d1edea;
}
.article-one .box .text{
  margin-right: 10px;
}
.article-one .box .number{
  border: 1px solid #d1d2d3;
  padding: 0px 4px;
  border-radius: 2px;
  color: #000;
  background-color: #fff;
}

.article-one .box-outter-big{
  display: block;
  z-index: 1;
  display: inline-block;
  position: relative;
}

.article-one > span, .article-one > div{
  display:inline-block; vertical-align:top;
}
.article-one .box.outter-big{
  position: relative;
  z-index: 1;
}

.el {
  white-space:nowrap;
  display: flex;
}
.el .box, .box {
  font-size: 12px;
  line-height: 14px;
  padding: 3px 0px;
  background-color: #F6F8FA;
  color: #444;
  /* text-shadow: 0 1px 0 rgba(255,255,255,.5); */
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
  z-index: 1;
  border: 1px solid #dadee0;
}
.el .box.category{
  font-size: 14px;
  line-height: 1.42857143;
  padding: 0px 0px;
  border-radius: 1px;
  background-color: #F6F8FA;
  color: #444;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1;
}
.el .box.folder, .box.folder {
  width: 20px;
  min-width: 20px;
  text-align: center;
  background-color: #EDEFF1;
}

.el .box:hover {
  background-color:#DCDDE1;
}
.box.outter {
  margin-right: 3px;
  padding: 3px 5px;
}
/*
.box.outter:hover{
  position: absolute;
  z-index: 999;
}
*/
.el .box.active{
  background-color: #d1edea;
}
.box.outter-big {
  margin-right: 2px;
}
.el .box .text {
  margin-right: 10px;
}
.el .box .number {
  border: 1px solid #d1d2d3;
  padding: 0px 4px;
  border-radius: 2px;
  color: #000;
  background-color: #fff;
}
/* .background-change {
  border: 1px solid #808589;
  border-radius: 3px;
  padding: 1px;
  margin-bottom: 2px;
  color: #808589;
}
.background-change:hover {
  border: 1px solid #808589;
  border-radius: 3px;
  padding: 1px;
  background: #808589;
  margin-bottom: 2px;
  color: white;
}
.background-change.active {
  color: white;
  background: #41a8e7;
  border-color: #41a8e7;
} */
.fa-cog{
  color: #6e6e6e;
  cursor: pointer;
  height: 28px;
  width: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 0px 0px 3px 0px;
  border: 1px solid #dadee0;
  margin-bottom: 5px;
}
.fa-cog.active {
  color: #41a8e7;
}
.block-link {
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: inline-block;
  background-color: rgb(0, 120, 109);
  border: none;
  border-radius: 2px;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .5px;
  cursor: pointer;
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: rgb(232, 230, 227);
}
.block-link:hover{
  box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 11px 0px, rgba(0, 0, 0, 0.15) 0px 4px 15px 0px;
}
.block-link a {
  color: #000;
}
/* margin-right: 5px; */
.empty-squad{width: 12.5px; height:12px; display: inline-block;}
.squad-level{
  width: 14px;
  height: 12px;
  /* border-left: 1px solid #a7a7a7; */
  display: inline-block;
  vertical-align: top;
  margin-right: -5px;
  position: relative;
  float: left;
  left: 3px;
}
.squad-level:before{
  content: '';
  display: block;
  width: 126%;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: 95%;
  left: 0px;
  background-color: #a7a7a7;
  height: 1px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
}
.squad-level:after{
  content: '';
  display: block;
  width: 1px;
  /* border-top: 1px solid #a7a7a7; */
  position: absolute;
  top: -106%;
  left: 0px;
  height: 200%;
  background-color: #a7a7a7;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
}
.ierarh-border{
  /* border-left: 1px solid #000; */
}
.item{
 margin-bottom: 2px;
}
.item .fa-folder-open{
  margin-right: -2px;
}
.item .fa-folder{ color: #009688; }
.item .fa-folder-open{ color: #009688;}
.item .empty-squad .fa-folder{ color: gray;}
/* .squad{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #999;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  margin-right: 4px;
  float: left;
} */
.squad + span {
  display: block;
  margin-left: 24px;
  line-height: 22px;
}
.level {
  position:relative;
}
.level-1{padding-left: 5px}
.level-2{padding-left: 19px}
.level-3{padding-left: 33px}
.level-4{padding-left: 47px}
.level-5{padding-left: 61px}
.level-6{padding-left: 75px}
.level-7{padding-left: 89px}
.level-8{padding-left: 103px}
.level-9{padding-left: 117px}
.level-10{padding-left: 131px}

.border {
  content: '';
  display: block;
  width: 1px;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: -78%;
  height: 174%;
  background-color: #a7a7a7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.border-2 {left: 8px}
.border-3 {left: 28px}
.border-4 {left: 48px}
/* .level-2::after{
  content: '';
  display: block;
  width: 1px;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: -78%;
  left: -17px;
  height: 174%;
  background-color: #a7a7a7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
} */

.level-3::after{}
.level-4::after{}
.level-5::after{}
.level-6::after{}
.level-7::after{}
.level-8::after{}
.level-9::after{}
.level-10::after{}
.level-1 .squad + span,
.level-2 .squad + span,
.level-3 .squad + span,
.level-4 .squad + span,
.level-5 .squad + span,
.level-6 .squad + span,
.level-7 .squad + span,
.level-8 .squad + span,
.level-9 .squad + span,
.level-10 .squad + span{margin-left: 39px;}
</style>
