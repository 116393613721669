<template>
  <div v-if="false" class="btn-context-menu"
    @click="showContextMenu"
    @keydown.f7="showContextMenu">
    <i class="fa fa-bars" aria-hidden="true"></i>
  </div>
  <div class="navigation" v-if="gettersAuthData.token">
    <div class="level-one" v-for="(item, ind) in gettersAuthData.menu"
        :key="ind">
      <router-link :to="item.url" :title="item.name" >
        <span><i class="fa" :class="item.icon" aria-hidden="true"></i></span>
      </router-link>
      <div v-if="item.items" class="level-two">
        <router-link v-for="(el, ind) in item.items" :key="ind" :to="el.url" :title="el.name" >
          <span><i class="fa" :class="el.icon" aria-hidden="true"></i>{{el.name}}</span>
        </router-link>
      </div>
    </div>

    <div class="level-one">
      <a href="javascript:void(0)" title="Уведомления в разработке" >
        <span><i class="fa fa-bell-o" aria-hidden="true"></i></span>
        <i style="text-indent:-99999px; display:inline-block;">!</i>
      </a>
    </div>

    <div class="level-one">
      <span class="menu-custom-item" title="АРБ"><img src="/img/logo25.png" alt="" /></span>
      <div class="level-two">
        <router-link to="#" title="Ссылка на документацию" >
          <span><i class="fa fa-book" aria-hidden="true"></i>Документация</span>
        </router-link>
        <router-link to="#" title="Красткая информация по странице" >
          <span><i class="fa fa-life-ring" aria-hidden="true"></i>Помощь</span>
        </router-link>
        <a href="https://wa-scm.ru" title="О программе" target="_blank">
          <span><i class="fa fa-ship" aria-hidden="true"></i>О программе</span>
        </a>
        <a href="#" title="О программе" target="_blank">
          <span><i class="fa fa-university" aria-hidden="true"></i>Наши курсы</span>
        </a>
      </div>
    </div>

    <div class="level-one">
      <LinkHint v-if="showMissing"
        :animate="false"
        icon="fa-fire"
        type="fire"
        hint="Есть недонастроенные товары для нескольких складов"
        align="br"
        path="/v2/stock/set"
        :query="{ mode: 1 }"
      />
    </div>

    <div class="level-one fix-top-right" v-if="flagNewHead">
      <SelectCustom
        v-model="selectForTemp.value"
        :def="selectForTemp.lbl"
        :options="selectForTemp.options"
        :needLogout="true"
      />
    </div>
    <div class="level-one" v-if="!flagNewHead">
      <div class="red-hover"
        @click="logout"
        @keydown.f7="logout">
        <span><i class="fa fa-sign-out" aria-hidden="true"></i></span>
      </div>
    </div>
  </div>

  <div class="wrapper-menu" v-if="flagNewHead">
    <div class="wrapper-left-search" v-if="gettersAuthData.token">
      <div class="search-left">
        <SuperSelectNew
          class="small-size"
          :field="gettersAuthData.stocks"
          @select="chgStock"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import store from '@/store/index';
import SuperSelectNew from '@/components/v2/serj/select/SuperSelectNew.vue';
import SelectCustom from '@/components/v2/serj/Select2.vue';
import LinkHint from '@/components/atoms/LinkHint.vue';

export default {
  name: 'WBNavigation',
  emits: ['showContextMenu'],
  components: {
    SuperSelectNew,
    SelectCustom,
    LinkHint,
  },
  data() {
    return {
      flag_menu: localStorage.getItem('user'),
    };
  },
  computed: {
    ...mapGetters('auth', {
      gettersAuthData: 'getAuthData',
      showMissing: 'getShowMissing',
    }),
    flagNewHead() {
      let res = false;
      if (this.$route.name === 'IfaceStockSet' || this.$route.name === 'Product') {
        res = true;
      }
      return res;
    },
    selectForTemp() {
      const temp = {
        lbl: 'Презайти в ЛК необходимо',
        value: {
          val: 0,
          lbl: 'Презайти в ЛК необходимо',
          cnt: 10,
          filter: false,
          click: true,
        },
        options: [
          {
            val: 2,
            lbl: 'Презайти в ЛК необходимо',
            cnt: 10,
            filter: false,
            click: false,
          },
        ],
      };

      if (this.gettersAuthData.info) {
        temp.lbl = this.gettersAuthData.info.company;
        temp.value.lbl = this.gettersAuthData.info.company;
      }
      if (this.gettersAuthData.info) {
        temp.options[0].lbl = this.gettersAuthData.info.fio;
      }
      return temp;
    },
  },
  methods: {
    ...mapActions('auth', {
      actionLogout: 'logout',
      actionChgStock: 'chgstock',
    }),
    logout() {
      this.actionLogout();
      this.$router.push('/login');
    },
    showContextMenu() {
      this.$emit('showContextMenu');
    },
    chgStock(val) {
      store.dispatch('auth/chgStock', val);
    },
  },
};
</script>

<style scoped>
  .fix-top-right{
    position: fixed !important;
    top: 0px;
    right: 12px;
  }
  .menu-custom-item{
    display: block;
    text-decoration: none;
    padding: 5px;
    line-height: 12px;
    transition: all 0.5s;
    border-radius: 0 0 18px 0px;
    font-weight: bold;
    color: #2c3e50;
    background: #f6f8fa;
    margin: 0 4px;
    border: 1px solid #dadee0;
    border-top: none;
    cursor: pointer;
  }
  .navigation .level-one:hover > .menu-custom-item{
    line-height: 24px;
    border-radius: 0 0 0px 0px;
    background-color: #e8ecf1;
  }
  .menu-custom-item img{
    width: 31px;
  }
  .wrapper-right-search {
    position: absolute;
    right: 40px;
    z-index: 1;
  }
  .navigation {
    position: fixed;
    margin: 0 auto;
    top: 0px;
    left: 44%; /* 50%; */
    margin-left: -148px;
    z-index: 3;
  }
  .navigation .level-one{
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
  .navigation .level-one > a, .navigation .level-one > .red-hover{
    display: block;
    text-decoration:none;
    padding:12px;
    line-height: 12px;
    transition:all 0.5s;
    border-radius: 0 0 18px 0px;
    font-weight: bold;
    color: #2c3e50;
    background: #f6f8fa;
    margin: 0 4px;
    border: 1px solid #dadee0;
    border-top: none;
    /* box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); */
  }
  .navigation .level-one > a.router-link-active{
    line-height:24px;
    background-color: #e8ecf1;
    cursor: default;
    border-color: #afb6b8;
    border-radius: 0 0 0px 0px;
  }
  .navigation .level-one > div.red-hover{
    margin-left:20px;
    cursor: pointer;
  }
  .navigation .level-one:hover > a{
    line-height:24px;
    border-radius: 0 0 0px 0px;
    /* box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15); */
    background-color: #e8ecf1;
  }
  .navigation div.red-hover:hover{
    background:#fb9292;
  }

  .level-two{
    /* display: none; */
    /* opacity: 0; */
    position: absolute;
    top: 48px;
    left: 4px;
    background: #fff;
    transition:opacity 0.5s;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }

  .level-one:hover .level-two{
    opacity: 1;
    /* display: block; */
  }
  .level-two > a{
    white-space: nowrap;
    background: #e4e8ed;
    text-align: left;
    display: block;
    /* padding: 4px 12px; */
    /* margin: 2px 0px; */
    color: #000;
    transition: all 0.3s;
    height: 0px; overflow:hidden;
  }
  .level-one:hover .level-two > a{
    padding: 6px 12px;
    margin: 2px 0px;
    height: 16px;
  }

  .level-two > a:hover {
    background: #c2c9d2;
  }

  .level-two > a.router-link-exact-active:hover {
    background: #5981ce;
  }
  .level-two > a i{
    margin-right: 6px;
  }
</style>
