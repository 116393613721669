<template>
  <div class="wrp-fire">
    <div class="warning-fire-2"
    @click="next"
    @keydown.f7="next"
    @mouseover="over"
    @mouseleave="leave"
    @focusin="over"
    @focusout="leave">
      <i class="fa" :class="icon" aria-hidden="true"></i>
    </div>
    <transition name="fade">
      <div v-show="show" class="subtext">
        {{hint}}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LinkHint',
  props: ['icon', 'hint', 'type', 'align', 'animate', 'path', 'query'],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    next() {
      let path;
      if (this.query) {
        path = { path: this.path, query: this.query };
      } else {
        path = this.path;
      }

      this.$router.push(path);
    },
    over() {
      this.show = true;
    },
    leave() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.warning-fire-2{
  display: block;
  text-decoration: none;
  padding: 12px;
  line-height: 12px;
  transition: all 0.5s;
  border-radius: 0 0 18px 0px;
  font-weight: bold;
  color: #2c3e50;
  background: #f6f8fa;
  margin: 0 4px;
  border: 1px solid #dadee0;
  border-top: none;
  cursor: pointer;
  background-color: #fddbdb;

  text-shadow: 0 -1px rgba(0,0,0,.1);
  -webkit-animation: pulsate 2.0s linear infinite;
  animation: pulsate 2.0s linear infinite;
}
.warning-fire-2:hover {
  line-height: 24px;
  border-radius: 0 0 0px 0px;
  background-color: #ffb1b1;
}

.warning-fire{
  width: 30px;
  height: 30px;
  line-height:30px;
  background: #ff5252;
  color: #fff;
  text-align: center;
  border-radius: 8px;

  text-shadow: 0 -1px rgba(0,0,0,.1);
  -webkit-animation: pulsate 1.2s linear infinite;
  animation: pulsate 1.2s linear infinite;

  cursor: pointer;
  float: right;
}
@-webkit-keyframes pulsate {
 50% { color: red; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff; }
}
@keyframes pulsate {
 50% { color: red; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff; }
}
.subtext{
  position: absolute;
  top: 60px;
  right: 10px;
  color: #000;
  min-width: 308px;
  border: 1px solid #852121;
  padding: 5px;
  background: #f1e1e1;
  color: #852121;
  font-size: 14px;
}
.subtext:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  right: 14px;
  top: -20px;
  border: 10px solid;
  border-color: transparent #852121 #852121 transparent;
}
.subtext:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  right: 15px;
  top: -18px;
  border: 9px solid;
  border-color: transparent #f1e1e1 #f1e1e1 transparent;
}
</style>
